import {
  StoryblokComponent,
  storyblokEditable,
  useStoryblokState,
} from "gatsby-source-storyblok"

import Layout from "../components/structural/layout"
import React from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => {
  const story = useStoryblokState(data?.page)

  const { seo, seo_author, seo_image } = story.content

  const components = story?.content?.content?.map(blok => (
    <StoryblokComponent blok={blok} doc={blok} key={blok._uid} />
  ))

  const firstComponent =
    story?.content?.content?.length > 0
      ? story?.content?.content[0]?.component
      : "unknown"

  return (
    <Layout
      seo={{
        author: seo_author,
        description: seo?.description,
        image: seo_image,
        title: seo?.title,
      }}
    >
      <div
        {...storyblokEditable(story?.content)}
        className={`mx-auto ${firstComponent !== "section_hero" && `mt-20`}`}
      >
        {components}
      </div>
    </Layout>
  )
}

export default IndexPage

export const Head = ({ data }) => {
  const story = useStoryblokState(data.page)

  const { seo, seo_author, seo_image } = story.content

  const siteSettings = JSON.parse(data?.settings?.content)

  return (
    <Seo
      settings={siteSettings}
      {...seo}
      author={seo_author}
      image={seo_image}
    />
  )
}

export const QUERY = graphql`
  query IndexDefaultQuery {
    page: storyblokEntry(full_slug: { eq: "home" }) {
      content
      name
      full_slug
      uuid
      id
      internalId
    }

    settings: storyblokEntry(full_slug: { eq: "site-settings" }) {
      content
      name
      full_slug
      uuid
      id
      internalId
    }
  }
`
